<template>
  <div class="packType">
    <el-card>
      <div class="pageBody">
        <div class="handleBoxWrap">
          <el-button size="small" type="primary" @click="addHandle()">新增包装分类</el-button>
        </div>
        <el-table v-loading="dataListLoading" :data="dataList" border :height="tabHeight">
          <table-tree-column prop="name" label="分类名称" header-align="center"></table-tree-column>
          <el-table-column label="操作" header-align="center" align="center" width="160">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="addHandle(scope.row)">新增子分类</el-button>
              <el-button type="text" size="small" @click="editHandle(scope.row)">编辑</el-button>
              <el-button type="text" size="small" @click="delHandle(scope.row.id)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-card>
    <edit-dialog ref="editDialog" @reload="getDataList"></edit-dialog>
  </div>
</template>
<script>
import editDialog from './packTypeAdd'
import TableTreeColumn from '@/components/table-tree-column'
import _ from 'lodash'

function getClientHeight () {
  let clientHeight = 0
  if (document.body.clientHeight && document.documentElement.clientHeight) {
    clientHeight = (document.body.clientHeight < document.documentElement.clientHeight) ? document.body.clientHeight : document.documentElement.clientHeight
  } else {
    clientHeight = (document.body.clientHeight > document.documentElement.clientHeight) ? document.body.clientHeight : document.documentElement.clientHeight
  }
  return clientHeight
}

export default {
  name: 'packType',
  components: { editDialog, TableTreeColumn },
  data () {
    return {
      dataListLoading: false,
      dataList: [],
      dataForm: {
        keyWord: ''
      },
      tabHeight: '400',
      levelList: [],
      sourceList: []
    }
  },
  mounted () {
    this.$nextTick(() => {
      // console.log(this.$utils.clientHeight() - 260 + 'px')
      this.tabHeight = getClientHeight() - 260 + 'px'
    })
  },
  activated () {
    this.getDataList()
  },
  methods: {
    // 获取数据列表
    getDataList () {
      this.dataListLoading = true
      this.$http.get('/packType/getAllList').then(({ data: res }) => {
        let { code, msg, data } = res
        console.log(code, msg, data)
        if (code === 0 && Array.isArray(data)) {
          let ret = this.parseTreeNode(data)
          console.log(ret)
          this.dataList = ret
        } else {
          this.$message.error(msg)
        }
        this.dataListLoading = false
      })
    },
    parseTreeNode (arr) {
      if (!arr.length) return []
      let group = _.groupBy(arr, (v) => (v.pid + ''))
      // console.log(group)
      let conn = function (v, c) {
        let r = _.cloneDeep(v)
        r.forEach((item) => {
          let id = item.id
          if (c) {
            item.parentName = c.name
          }
          let children = group[id + '']
          if (Array.isArray(children) && children.length) {
            item.children = conn(children, item)
          } else {
            item.isLeaf = true
            item.children = undefined
          }
        })
        return r
      }
      let ret = conn(group['0'])
      return ret
    },
    addHandle (row) {
      let pid = '0'
      let parentName = ''
      if (row) {
        pid = row.id
        parentName = row.name
      }
      this.$refs.editDialog.init({ pid, parentName })
    },
    // 修改
    editHandle (row) {
      console.log(row)
      this.$refs.editDialog.init(row)
    },
    // 删除
    delHandle (id) {
      // console.log(arguments)
      this.$confirm('确定删除该数据吗？', '包装分类').then(() => {
        return this.$http.delete(`/packType/${id}`)
      }).then(({ data: res }) => {
        let { code, msg } = res
        console.log(code, msg)
        if (code !== 0) {
          this.$message.error(msg)
        }
        this.getDataList()
      })
    }
  }
}
</script>
<style lang="scss">
  .packType {
    padding: 12px 20px 0;
    .handleBoxWrap {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      margin-bottom: 10px;

      .el-select,.el-range-editor.el-input__inner,.el-input{
        margin: 0;
        margin-right: 10px;
        max-width: 200px;
      }
    }
  }
</style>
