<template>
  <el-dialog :title="title" :visible.sync="visible" class="packTypeAdd" :close-on-click-modal="false" :close-on-press-escape="false" @close="close">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" size="small" @keyup.enter.native="submit" label-width="120px">
      <el-form-item prop="pid" label="上级分类">
        <el-input v-model="parentName" disabled>
        </el-input>
      </el-form-item>
      <el-form-item prop="name" label="分类名称">
        <el-input v-model="dataForm.name" placeholder="请填写包装分类名称"></el-input>
      </el-form-item>
    </el-form>
    <template slot="footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="submit">确定</el-button>
    </template>
  </el-dialog>
</template>
<script>
import debounce from 'lodash/debounce'
import _ from 'lodash'
export default {
  name: 'packTypeAdd',
  data () {
    return {
      visible: false,
      id: null,
      menuListVisible: false,
      parentName: '[根节点]',
      pidError: '',
      menuList: [],
      dataForm: {
        name: '',
        pid: null
      },
      dataRule: {
        name: [{ required: true, message: '请填写包装分类名称', trigger: 'blur' }]
      }
    }
  },
  computed: {
    title () {
      return `${this.id ? '修改' : '新增'}包装分类名称`
    }
  },
  methods: {
    init (item) {
      this.getMenuTree()
      let pid = 0
      // console.log(item)
      this.parentName = '[根节点]'
      let id = null
      if (item) {
        if (item.id) id = item.id
        if (item.pid) pid = item.pid
        if (item.parentName) {
          this.parentName = item.parentName
        }
      }
      this.id = id
      let form = id ? _.cloneDeep(item) : this.$options.data().dataForm
      form.pid = pid + ''
      this.$set(this, 'dataForm', form)
      this.visible = true
      this.$nextTick(() => {
        this.$refs.dataForm.clearValidate()
      })
    },
    // 获取包装分类名称信息
    getInfo (id) {
      this.$http.get(`/packType/${id}`).then(({ data: res }) => {
        let { code, msg, data } = res
        // console.log(code, msg, data)
        if (code === 0) {
          let form = _.cloneDeep(data)
          this.$set(this, 'dataForm', form)
        } else {
          this.$message.error(msg)
        }
      })
    },
    resetDefaultHandle () {
      this.$set(this.dataForm, 'pid', '0')
      this.parentName = '[根节点]'
    },
    treeCurrentChanged (item) {
      // console.log(arguments)
      this.pidError = ''
      let pid = item.id
      if (this.id && pid === this.id) {
        this.pidError = '不能将自己设置为上级分类'
      } else {
        this.$set(this.dataForm, 'pid', pid)
        this.parentName = item.name
      }
    },
    getMenuTree () {
      this.$http.get('/packType/getAllList').then(({ data: res }) => {
        let { code, msg, data } = res
        console.log(code, msg, data)
        if (code === 0 && Array.isArray(data)) {
          let ret = this.parseTreeNode(data)
          console.log(ret)
          this.menuList = ret
        }
        if (code !== 0) {
          this.$message.error(msg)
        }
      })
    },
    parseTreeNode (arr) {
      let group = _.groupBy(arr, (v) => (v.pid + ''))
      // console.log(group)
      let conn = function (v, c) {
        let r = _.cloneDeep(v)
        r.forEach((item) => {
          let id = item.id
          if (c) {
            item.parentName = c.name
          }
          let children = group[id + '']
          if (Array.isArray(children) && children.length) {
            item.children = conn(children, item)
          } else {
            item.isLeaf = true
            item.children = undefined
          }
        })
        return r
      }
      let ret = conn(group['0'])
      return ret
    },
    // 关闭窗口
    close () {
      this.visible = false
      this.$emit('reload')
    },
    // 提交数据
    submit: debounce(function () {
      this.$refs['dataForm'].validate((valid) => {
        if (!valid) return false
        let form = _.cloneDeep(this.dataForm)
        let func = this.id ? 'put' : 'post'
        let u = '/packType'
        if (form.pid !== '0' && !this.id) u += '/addChild'
        this.$http[func](u, form).then(({ data: res }) => {
          let { code, msg } = res
          if (code !== 0) {
            this.$message.error(msg)
          } else {
            this.close()
          }
        })
      })
    }, 1000, { 'leading': true, 'trailing': false })
  }
}
</script>
<style lang="scss">
.packTypeAdd {
  .fullWidth {
    width: 100%;
  }
}
</style>
